import { navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import { Context } from "../components/store";
import RegistrationForm from "../components/user-management/RegistrationForm";
import { addError, addSuccess } from "../utils/errors";
import { urlService } from "../_services";

type ComponentProps = {
  location: any;
};

export default ({ location }: ComponentProps) => {
  const parameters = new URLSearchParams(location.search);
  const key = parameters.get("key");

  const [, setContext] = useContext(Context);

  useEffect(() => {
    // If "key" is given as parameter, we send the information to the activation endpoint
    if (!key) {
      return;
    }
    const url = urlService.getUrl("/user/activate-registration");
    urlService
      .getAxios()
      .post(url, {
        activationKey: key,
      })
      .then(() => {
        addSuccess(setContext, "Die Aktivierung war erfolgreich. Sie können sich nun anmelden.");
        navigate("/login");
      })
      .catch((error) => {
        addError(setContext, "Die Registrierung konnte leider nicht abgeschlossen werden");
      });
  }, [key]);

  return (
    <div>
      <h2>Registrierung</h2>
      {key ? null : (
        <div style={{ maxWidth: "400px", margin: "auto" }}>
          <RegistrationForm />
        </div>
      )}
    </div>
  );
};
